<template>
    <div
        style="width: 100%; height: 100%; margin: 0; padding: 0; position: relative">
        <component
            :is="getComponent(fileName)"
            v-if="cameraConfig"
            ref="model"
            :background="background"
            :background-alpha="backgroundAlpha"
            :background-color="backgroundColor"
            :clone-camera="cameraConfig.cloneCamera"
            :clone-scene="cameraConfig.cloneScene"
            :gl-options="{ preserveDrawingBuffer: true }"
            :height="height"
            :rotation="rotation"
            :src="src"
            :width="width"
            @onObject="obj => $emit('onObject', obj)"
            @on-load="onLoad"
            @on-error="onError"
            @on-progress="onProgress"/>
        <component
            :is="getComponent(fileName)"
            v-else
            ref="model"
            :background="background"
            :background-alpha="backgroundAlpha"
            :background-color="backgroundColor"
            :gl-options="{ preserveDrawingBuffer: true }"
            :height="height"
            :rotation="rotation"
            :src="src"
            :width="width"
            @onObject="obj => $emit('onObject', obj)"
            @on-load="onLoad"
            @on-error="onError"
            @on-progress="onProgress"/>
        <div
            v-if="!disableLoading"
            v-show="loading || sideLoading"
            class="page-preloader"/>
    </div>
</template>

<script>
    import ModelObj from "@/components/3d-model/model-obj";
    import ModelStl from "@/components/3d-model/model-stl";
    import ModelCollada from "@/components/3d-model/model-collada";
    import ModelPly from "@/components/3d-model/model-ply";
    import ModelFbx from "@/components/3d-model/model-fbx";
    import ModelGltf from "@/components/3d-model/model-gltf";

    import { dataURLtoFile } from "@/utils/index.utils";

    export default {
        name: "model",
        components: {
            ModelObj,
            ModelStl,
            ModelCollada,
            ModelPly,
            ModelFbx,
            ModelGltf
        },
        data() {
            return {
                loading: true
            };
        },
        props: {
            backgroundColor: {
                type: [String, Number],
                default: 0xffffff
            },
            backgroundAlpha: {
                type: Number,
                default: 1
            },
            background: {
                type: String,
                default: null
            },
            disableLoading: {
                type: Boolean,
                default: false
            },
            src: {
                type: String,
                default: null
            },
            fileName: {
                type: String,
                default: null
            },
            fileId: {
                type: [Number, String],
                default: null
            },
            width: {
                type: Number,
                default: null
            },
            height: {
                type: Number,
                default: null
            },
            cameraConfig: {
                type: Object,
                default: null
            },
            onBefore: {
                type: Function,
                default: null
            },
            sideLoading: {
                type: Boolean,
                default: false
            },
            position: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            rotation: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            scale: {
                type: Object,
                default() {
                    return { x: 1, y: 1, z: 1 };
                }
            },
            lights: {
                type: Array,
                default() {
                    return [];
                }
            },
            cameraPosition: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            cameraRotation: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            }
            // size: {
            //   type: Object,
            //   default: null
            // }
        },
        beforeCreate() {
            this.$emit("loading", true);
        },
        mounted() {
            if (this.$refs.model) {
                this.$emit("input", this.$refs.model);
                this.$emit("getSnapshot", this.getSnapshot);

                this.$emit("setControlls", {
                    snapshot: this.getSnapshot,
                    file_id: this.fileId
                });
            }
        },
        methods: {
            getComponent(src) {
                if (!src) return null;

                const urlParts = src.toLowerCase().split(".");
                const format = urlParts[urlParts.length - 1];
                switch (format) {
                    case "obj":
                        return "model-obj";
                    case "stl":
                        return "model-stl";
                    case "dae":
                        return "model-collada";
                    case "ply":
                        return "model-ply";
                    case "fbx":
                        return "model-fbx";
                    case "gltf":
                        return "model-gltf";
                    default:
                        return "model-fbx";
                }
            },
            getSnapshot(name) {
                this.base64 = this.$refs.model.renderer.domElement.toDataURL(
                    "image/png",
                    1
                );
                const result = [
                    dataURLtoFile(this.base64, (name ?? "3d-snapshot") + ".png"),
                    this.base64
                ];
                return result;
            },
            onLoad(e) {
                this.loading = false;
                this.$emit("onLoad", {
                    id: this.$props.fileId,
                    name: this.$props.fileName
                });
            },
            onError(e) {
                console.error(e);
                this.$emit("on-error", e);
            },
            onProgress(e){

            }
        }
    };
</script>
