<template>
    <div
        style="
            width: 100%;
            height: 100%;
            margin: 0;
            border: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        ">
        <canvas
            v-if="supportWebGL"
            ref="canvas"
            style="width: 50%; height: 100%"/>
        <div v-else>
            <slot>
                Your browser does not seem to support
                <a
                    href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation"
                    style="color: #000">
                    WebGL
                </a>
                .<br/>'
            </slot>
        </div>
    </div>
</template>

<script>
    import {
        Color,
        LinearEncoding,
        Object3D,
        PerspectiveCamera,
        Raycaster,
        Scene,
        TextureLoader,
        Vector2,
        Vector3,
        WebGLRenderer,
        sRGBEncoding,
        PMREMGenerator,
        MeshStandardMaterial
    } from "three";
    import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
    import { getCenter, getSize } from "./util";

    const supportWebGL = (() => {
        try {
            const canvas = document.createElement("canvas");
            return !!(
                window.WebGLRenderingContext &&
                (canvas.getContext("webgl") ||
                    canvas.getContext("experimental-webgl"))
            );
        } catch (e) {
            return false;
        }
    })();

    const DEFAULT_GL_OPTIONS = {
        antialias: true,
        alpha: true
    };

    export default {
        props: {
            src: {
                type: String,
                default: ""
            },
            width: {
                type: Number,
                default: 0
            },
            height: {
                type: Number,
                default: 0
            },
            position: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            rotation: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            scale: {
                type: Object,
                default() {
                    return { x: 1, y: 1, z: 1 };
                }
            },
            lights: {
                type: Array,
                default() {
                    return [];
                }
            },
            cameraPosition: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            cameraRotation: {
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                }
            },
            cameraUp: {
                type: Object,
                default: null
            },
            cameraLookAt: {
                type: Object,
                default: null
            },
            background: {
                type: String,
                default: ""
            },
            backgroundColor: {
                type: [String, Number],
                default: 0xffffff
            },
            backgroundAlpha: {
                type: Number,
                default: 1
            },
            controlsOptions: {
                type: Object,
                default() {
                    return {};
                }
            },
            crossOrigin: {
                type: String,
                default: "anonymous"
            },
            requestHeader: {
                type: Object,
                default() {
                    return {};
                }
            },
            outputEncoding: {
                type: Number,
                default: LinearEncoding
            },
            glOptions: {
                type: Object,
                default() {
                    return {};
                }
            },
            cloneCamera: {
                type: Object,
                default: null
            },
            cloneScene: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                supportWebGL,
                size: {
                    width: this.width,
                    height: this.height
                },
                object: null,
                raycaster: new Raycaster(),
                mouse: new Vector2(),
                camera: new PerspectiveCamera(45, 1, 0.01, 100000),
                scene: new Scene(),
                wrapper: new Object3D(),
                renderer: null,
                controls: null,
                allLights: [],
                clock: typeof performance === "undefined" ? Date : performance,
                reqId: null,
                pngCubeRenderTarget: null,
                pngBackground: null,
                pmremGenerator: null
            };
        },
        computed: {
            hasListener() {
                const events = this._events;
                const result = {};

                ["on-mousemove", "on-mouseup", "on-mousedown", "on-click"].forEach(
                    (name) => {
                        result[name] = !!events[name] && events[name].length > 0;
                    }
                );

                return result;
            }
        },
        mounted() {
            if (!this.width || !this.height) {
                this.$nextTick(()=> {
                    this.size = {
                        width: this.$el.clientWidth,
                        height: this.$el.clientHeight
                    };
                })
            }

            const options = Object.assign({}, DEFAULT_GL_OPTIONS, this.glOptions, {
                canvas: this.$refs.canvas
            });

            if (this.cloneCamera) {
                this.camera = this.cloneCamera;
            }
            if (this.cloneScene) {
                this.scene = this.cloneScene;
            }

            this.renderer = new WebGLRenderer(options);
            this.renderer.shadowMap.enabled = true;
            this.renderer.outputEncoding = this.outputEncoding;

            this.controls = new OrbitControls(
                this.camera,
                this.renderer.domElement
            );
            this.controls.type = "orbit";

            this.pmremGenerator = new PMREMGenerator( this.renderer );
            this.pmremGenerator.compileEquirectangularShader();
            this.loadBackground();
            this.scene.add(this.wrapper);

            this.load();
            this.update();

            this.$el.addEventListener("mousedown", this.onMouseDown, false);
            this.$el.addEventListener("mousemove", this.onMouseMove, false);
            this.$el.addEventListener("mouseup", this.onMouseUp, false);
            this.$el.addEventListener("click", this.onClick, false);

            window.addEventListener("resize", this.onResize, false);

            this.animate();

            let { position, rotation, scale } = this.camera;
            this.$emit("onObject", {
                controls: this.controls,
                getRotation: () => {
                    return this.camera.position;
                },
                position,
                rotation,
                scale,
                cameraPosition: position,
                cameraRotation: rotation,
                cloneScene: this.scene,
                cloneCamera: this.camera
            });
        },
        beforeDestroy() {
            cancelAnimationFrame(this.reqId);

            // this.scene.dispose();
            this.renderer.dispose();

            if (this.controls) {
                this.controls.dispose();
            }

            this.$el.removeEventListener("mousedown", this.onMouseDown, false);
            this.$el.removeEventListener("mousemove", this.onMouseMove, false);
            this.$el.removeEventListener("mouseup", this.onMouseUp, false);
            this.$el.removeEventListener("click", this.onClick, false);

            window.removeEventListener("resize", this.onResize, false);
        },
        watch: {
            background() {
                this.loadBackground();
            },
            src() {
                this.load();
            },
            cloneCamera: {
                handler(val) {
                    this.camera = val;
                    this.controls = new OrbitControls(this.camera, this.$el);
                    this.controls.type = "orbit";
                }
            },

            rotation: {
                deep: true,
                handler(val) {
                    if (!this.object) return;

                    this.camera.position.x = val.x; //-199.05535194467424
                    this.camera.position.y = val.y; //5.044604225365018
                    this.camera.position.z = val.z; //91.6616390180285

                    this.controls.update();
                }
            },
            position: {
                deep: true,
                handler(val) {
                    if (!this.object) return;
                    //console.log("position", val.x, val.y, val.z)
                    this.object.position.set(val.x, val.y, val.z);
                }
            },
            scale: {
                deep: true,
                handler(val) {
                    if (!this.object) return;
                    //console.log("scale", val.x, val.y, val.z)
                    this.object.scale.set(val.x, val.y, val.z);
                }
            },
            lights: {
                deep: true,
                handler() {
                    this.updateLights();
                }
            },
            size: {
                deep: true,
                handler() {
                    this.updateCamera();
                    this.updateRenderer();
                }
            },
            controlsOptions: {
                deep: true,
                handler() {
                    this.updateControls();
                }
            },
            backgroundAlpha() {
                this.updateBackground();
            },
            backgroundColor() {
                this.updateBackground();
            }
        },
        methods: {
            loadBackground() {
                if (this.background) {
                    const loader = new TextureLoader();
                    loader.load(this.background, (texture) => {
                        this.scene.background = texture;
                    });
                } else {
                    this.scene.background = null;
                }
            },
            onResize() {
                if (!this.width || !this.height) {
                    this.$nextTick(() => {
                        this.size = {
                            width: this.$el.clientWidth,
                            height: this.$el.clientHeight
                        };
                    });
                } else {
                    this.$nextTick(() => {
                        this.size = {
                            width: this.width,
                            height: this.height
                        };
                    });
                }
            },
            onMouseDown(event) {
                if (!this.hasListener["on-mousedown"]) return;

                const intersected = this.pick(event.clientX, event.clientY);
                this.$emit("on-mousedown", intersected);
            },
            onMouseMove(event) {
                if (!this.hasListener["on-mousemove"]) return;

                const intersected = this.pick(event.clientX, event.clientY);
                this.$emit("on-mousemove", intersected);
            },
            onMouseUp(event) {
                if (!this.hasListener["on-mouseup"]) return;

                const intersected = this.pick(event.clientX, event.clientY);
                this.$emit("on-mouseup", intersected);
            },
            onClick(event) {
                if (!this.hasListener["on-click"]) return;

                const intersected = this.pick(event.clientX, event.clientY);
                this.$emit("on-click", intersected);
            },
            pick(x, y) {
                if (!this.object) return null;

                const rect = this.$el.getBoundingClientRect();

                x -= rect.left;
                y -= rect.top;

                this.mouse.x = (x / this.size.width) * 2 - 1;
                this.mouse.y = -(y / this.size.height) * 2 + 1;

                this.raycaster.setFromCamera(this.mouse, this.camera);

                const intersects = this.raycaster.intersectObject(
                    this.object,
                    true
                );

                return (intersects && intersects.length) > 0 ? intersects[0] : null;
            },
            update() {
                //console.log('update!!')
                this.updateRenderer();
                this.updateCamera();
                this.updateLights();
                this.updateControls();
            },
            updateModel() {
                //console.log('updateModel!!')
                const { object } = this;

                if (!object) return;

                const { position } = this;
                const { rotation } = this;
                const { scale } = this;

                object.position.set(position.x, position.y, position.z);
                object.rotation.set(rotation.x, rotation.y, rotation.z);
                object.scale.set(scale.x, scale.y, scale.z);
            },
            updateRenderer() {
                //console.log('updateRenderer!!')
                const { renderer } = this;

                renderer.setSize(this.size.width, this.size.height);
                renderer.setPixelRatio(window.devicePixelRatio || 1);
            },

            updateBackground() {
                const { renderer } = this;
                renderer.setClearColor(new Color(this.backgroundColor));
                renderer.setClearAlpha(this.backgroundAlpha);
            },
            updateCamera() {
                if (this.cloneCamera) {
                    return;
                }
                //console.log('updateCamera!!')
                const { camera } = this;
                const { object } = this;

                camera.aspect = this.size.width / this.size.height;
                camera.updateProjectionMatrix();

                if (!this.cameraLookAt && !this.cameraUp) {
                    if (!object) return;

                    const distance = getSize(object).length();

                    camera.position.set(
                        this.cameraPosition.x,
                        this.cameraPosition.y,
                        this.cameraPosition.z
                    );
                    camera.rotation.set(
                        this.cameraRotation.x,
                        this.cameraRotation.y,
                        this.cameraRotation.z
                    );

                    if (
                        this.cameraPosition.x === 0 &&
                        this.cameraPosition.y === 0 &&
                        this.cameraPosition.z === 0
                    ) {
                        camera.position.z = distance;
                    }

                    camera.lookAt(new Vector3());
                } else {
                    camera.position.set(
                        this.cameraPosition.x,
                        this.cameraPosition.y,
                        this.cameraPosition.z
                    );
                    camera.rotation.set(
                        this.cameraRotation.x,
                        this.cameraRotation.y,
                        this.cameraRotation.z
                    );
                    //console.log('cameraUp', this.cameraUp.x, this.cameraUp.y, this.cameraUp.z)
                    camera.up.set(
                        this.cameraUp.x,
                        this.cameraUp.y,
                        this.cameraUp.z
                    );

                    camera.lookAt(
                        new Vector3(
                            this.cameraLookAt.x,
                            this.cameraLookAt.y,
                            this.cameraLookAt.z
                        )
                    );
                }
            },
            updateLights() {

            },
            updateControls() {
                if (this.controlsOptions) {
                    Object.assign(this.controls, this.controlsOptions);
                }
            },
            load() {
                if (!this.src || !this.loader) return;

                new TextureLoader().load( "/img/hdri.jpg",  ( texture ) => {

                    texture.encoding = sRGBEncoding;

                    this.pngCubeRenderTarget = this.pmremGenerator.fromEquirectangular( texture );

                    this.pngBackground = this.pngCubeRenderTarget.texture;

                    if (this.object) {
                        this.wrapper.remove(this.object);
                    }

                    if (!this.cloneScene) {
                        this.loader.setRequestHeader(this.requestHeader);
                        this.loader.load(
                            this.src,
                            (...args) => {
                                const object = this.getObject(...args);

                                if (this.process) {
                                    this.process(object);
                                }

                                let material = new MeshStandardMaterial( {
                                    envMapIntensity: 1.0,
                                    metalness: 0.1,
                                    roughness: 0
                                } );
                                object.traverse((child) => {
                                    if (child.isMesh) {
                                        child.material = material
                                        child.material.envMap = this.pngCubeRenderTarget ? this.pngCubeRenderTarget.texture : null;
                                        child.material.needsUpdate = true;
                                    }
                                });

                                this.addObject(object);

                                this.$emit("on-load");
                            },
                            (xhr) => {
                                this.$emit("on-progress", xhr);
                            },
                            (err) => {
                                this.$emit("on-error", err);
                            }
                        );
                    } else {
                        this.$emit("on-load");
                    }
                } );

            },
            getObject(object) {
                return object;
            },
            addObject(object) {
                const center = getCenter(object);
                this.wrapper.position.copy(center.negate());

                this.object = object;
                this.wrapper.add(object);

                this.updateCamera();
                this.updateModel();
            },
            animate() {
                this.reqId = requestAnimationFrame(this.animate);
                this.render();
            },
            render() {
                this.renderer.render(this.scene, this.camera);
            }
        }
    };
</script>
